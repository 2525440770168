

.next-level-teaser {
    padding: 65/@px 45/@px;
    font-size: 24/@px;
    line-height: 32/@px;
    margin-bottom: 60/@px;

    @media @screen-md-min {
        padding: 75/@px;
        display: grid;
        grid-template-columns: 2fr 1fr;
        align-items: center;
        column-gap: 32/@px;
        margin-bottom: 0;
    }

    .headline {
        font-size: 55/@px;
        line-height: 55/@px;
    }

    &__button {
        margin-top: 50/@px;

        @media @screen-md-min {
            text-align: center;
            margin-top: 0;
        }

        .button {
            font-size: 22/@px;
        }

    }
}