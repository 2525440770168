
.video {
    background: #ffffff;
}


.video-container {
    width: 100%;
    aspect-ratio: 16 / 9;
    position: relative;
    overflow: hidden;
    margin-bottom: 32/@px;
    border-radius: @border-radius-default;

}

.video-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}

.video-disclaimer {
    padding: 20px 20px 0;
    border: 1px solid #000;
    margin-bottom: 20px;
    background: @color-white;
    color: @color-black;
}

.video__disclaimer-txt {
    margin-bottom: 10px;
}

.video__disclaimer-btn {
    margin-bottom: 20px;
    cursor: pointer;
}

.video__preview-teaser {
    position: relative;
    width: 100%;
    aspect-ratio: 16 / 9;
    overflow: hidden;
    border-radius: @border-radius-default;
    margin-bottom: 32/@px;

    &:after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: @border-radius-default;
        background: @color-black;
        opacity: .3;
    }
}

.video__play-btn {
    display: block;
    width: 110px;
    height: 110px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    z-index: 1;
    background: none;
    border: none;
    transition: transform .35s ease;
    cursor: pointer;

    &:hover,
    &:focus {
        transform: translate(-50%, -50%) scale(1.1);
    }

    .nasicher-icon {
        width: 110px;
        height: 110px;
    }
}

.video__preview-image {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}

.component--video-teaser,
.component--bild-teaser {
    padding-top: 0;
}

.bild-teaser,
.video-teaser {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    grid-gap: 25/@px;
    border-top: 2px solid var(--primary-color);
    padding: 50/@px 0 0;
    margin: 0 auto;
    max-width: 1050/@px;

    &__txt {
        max-width: 600/@px;
    }

    @media @screen-md-min {
        grid-template-columns: 300/@px 1fr;
    }
}

.video__transcript {
    color: @color-black;
    font-size: 16/@px;
}
