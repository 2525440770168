.component--current-events, .current-events {
	table {
		border-collapse: collapse;
	}

	thead {
		background: @black-5;
		color: @color-black;
		text-align: left;
		border-bottom: 2px solid @black-20;

		th {
			padding: 12px 16px;
			font-weight: 600;
		}
	}

	tbody {
		tr {
			border-bottom: 1px solid @black-20;
			transition: background 0.2s ease;

			&:hover {
				background: @black-5;
			}

			td {
				padding: 10px 16px;
			}

			td:first-child {
				white-space: nowrap;
				width: 1px;
				max-width: max-content;
			}

			a {
				text-decoration: none;
				color: var(--primary-color);

				.ic-arrow4-right {
					margin-left: 8px;
				}
			}
		}
	}

	.button--primary {
		display: inline-block;
		margin-top: 1rem;
	}
}

.component--current-events-list p a {
	color: var(--primary-color);
}