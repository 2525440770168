@import "variables.less";

.site-nav__menu-board {
    display: none;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.9);
    width: auto;
    padding-top: 16/@px;
    left: auto;
    top: 50px;
    z-index: 10;
    box-shadow: 0 1px 10px -2px rgba(0,0,0,.61);
    margin-top: 30px;
    overflow-y: auto;
    max-height: 80vh;
    scrollbar-width: thin;

    @media @screen-lg-min {
        top: 75px;
    }

    & .col-1 {
        max-width: unset;
        padding: 0 16/@px;
    }

    @media @screen-sm-max {
        background: none;
        margin-top: 0;
        box-shadow: none;
        scrollbar-width: none;

        & .col-1 {
            max-width: 100%;
        }

        [class*="col-"] {
            overflow: unset;
            padding-left: unset;
        }
    }

    & ul li {
        list-style-type: none;
    }

    & ul li a {
        color: @color-white;
        font-size: 24/@px;
        text-decoration: none;
        transition: color 0.2s ease;
        display: flex;
        align-items: center;

        @media @screen-md-min {
            font-size: 16/@px;
        }
    }

    & ul li a:hover {
        color: @color-white;
    }

    & .nasicher-icon {
        display: none;

        @media @screen-md-min {
            display: block;
            width: 10/@px;
            height: 10/@px;
            margin-right: 5/@px;
        }
    }
}

.site-nav__menu-board.show-submenu {
    display: block;
    position: relative;
    top: 0;

    & .row {
        display: block;
    }
}

.site-nav__menu-item {
    border-bottom: 1px solid @color-white;

    & .active-link {
        color: @color-white;
    }

    &:last-child {
        border-bottom: none;
    }
}

.sublevel {
    &__parent {

        &-item {
            display: flex;
            margin: 1em 0;
            padding: 0 0 16/@px 0;
            border-bottom: 1px solid white;
        }
    }

    &__link {
        display: inline-block;
        padding: 8px 24px 8px 8px;
        white-space: nowrap;
        width: 100%;
    }

}