
.component--apprenticeships-teaser {

    > .inner {
        padding: 0;
    }

    .apprenticeships-teaser {
        padding: 100/@px 50/@px 30/@px;

        @media @screen-md-min {
            display: grid;
            grid-template-columns: 2.5fr 3fr;
            align-items: center;
        }

        @media @screen-lg-min {
            padding: 60/@px 0;
        }
    }
}