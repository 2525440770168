.component--job-folders {
    max-width: none;

    > .inner {
        padding: 0;
    }
}

.folder-list {
    display: flex;
    flex-wrap: nowrap;
    list-style: none;
    width: 100%;
    position: relative;

    @media @screen-sm-min {
        justify-content: center;
    }

    @media @screen-xs-max {
        overflow-x: auto;
    }
}

.folder-list__item {
    margin: 0 15/@px 50/@px;
    width: 80%;
    flex-shrink: 0;

    &:first-child { margin-left: 0; }
    &:last-child { margin-right: 0; }

    @media @screen-sm-min {
        width: ~'calc(25% - 22.5px)';
    }

    .folder-list__item-img {
        margin-bottom: 15/@px;
    }
}

.folder-list__item-link {
    transition: transform .35s ease, opacity .35s ease;
    display: block;
    text-decoration: none;
    position: relative;
    opacity: .7;
    text-align: center;


    &:hover,
    &:focus {
        text-decoration: none;
        opacity: 1;
        transform: scale(1.1);

        .folder-list__item-img,
        .folder-list__item-icon,
        .folder-list__item-title {
            opacity: 1;
        }

    }
}

.folder-list__item-title {
    display: block;
    transition: opacity .35s ease;
    text-transform: uppercase;
    color: #ffffff;
    font-size: 18px;
    .font-weight-bold();
    margin: 0 auto;
    max-width: 250px;

    @media @screen-sm-min {
        opacity: 0;
    }
}

.folder-list__item-icon {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10/@px;
    transition: opacity .35s ease;
    color: @color-black;
    font-size: 16/@px;
    .font-weight-bold();
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding: 6/@px 16/@px 4/@px;
    background: @color-white;
    border-radius: @border-radius-big;

    @media @screen-sm-min {
        opacity: 0;
    }
}

