
.area-intro {
    color: @color-white;
    margin-bottom: 50/@px;

    .intro__txt {
        .headline,
        .preheadline,
        .subheadline {
            color: currentColor;
        }
    }
}


// COMPONENT TEXT-INTRO
.intro-text {
    .font-weight-medium();
    font-size: 22/@px;
    line-height: 30/@px;

    @media @screen-lg-min {
        font-size: 24/@px;
        line-height: 32/@px;
    }

    .headline {
        .font-weight-black();
        margin-bottom: 25/@px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .preheadline {
        background-color: var(--primary-color);
        color: @color-white;
        font-size: 16/@px;
        line-height: 20/@px;
        padding: 8/@px 16/@px 4/@px;
        border-radius: @border-radius-big;
        margin-bottom: 16/@px;
        display: flex;
    }

    &--no-background .preheadline {
        .font-weight-bold();
        background: transparent;
        color: @color-black;
        padding: 0;

        .bg-red & {
            color: @color-white;
        }
    }
}

.intro__content {
    padding: 80/@px 0;
    align-items: flex-end;

    @media @screen-lg-min {
        padding: 150/@px 0;
    }
}

.intro__header-img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 20% 0;
    border-radius: 0;

    @media @screen-sm-min {
        object-position: 10% 0;
    }

    @media @screen-lg-min {
        object-position: 0;
    }
}


.intro__title {
    .font-weight-bold();
    font-weight: 900;
    font-size: 25/@px;
    line-height: 25/@px;
    text-align: center;
    text-transform: uppercase;
    color: @color-white;

    &--xl {
        font-size: 30/@px;
        line-height: 36/@px;
    }

    &--pre,
    &--sub {
        max-width: 60%;
        min-width: 250/@px;
        display: block;
        margin: 0 auto 16/@px;

        @media @screen-md-min {
            max-width: 460/@px;
        }
    }

    &--highlight {
       font-size: 78/@px;
       line-height: 94/@px;
       display: block;
       text-align: center;

       .intro__title--xl & {
            font-size: 78/@px;
            line-height: 94/@px;
        }


       @media @screen-md-min {
            font-size: 65/@px;
            line-height: 65/@px;

            .intro__title--xl & {
                font-size: 160/@px;
                line-height: 192/@px;
            }

        }
    }
}

.button-row {
    text-align: center;
    margin-top: 25/@px;
}

.intro__txt {
    margin: 0 auto;
    .font-weight-bold();
    font-size: 24/@px;
    line-height: 24/@px;
    max-width: 770/@px;
    text-align: center;
    color: @color-white;

    a {
        color: @color-white;
    }
}

.intro__video {
    margin-top: 48/@px;

    @media @screen-md-min {
        margin-top: 102/@px;
    }
}

.intro__header {
    position: relative;

    .intro__content-wrapper {
        z-index: 1;
        position: relative;
        display: flex;
        justify-content: center;

        &.justify-items--left { justify-content: flex-start; }
        &.justify-items--right { justify-content: flex-end; }
    }

    &:after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        background-image: var(--gradient);
        background-blend-mode: multiply;
        pointer-events: none;

        .theme-commercial & {
            --gradient: @gradient-commercial;
        }

        .theme-technical & {
            --gradient: @gradient-technical;
        }

        .theme-railway-engineering & {
            --gradient: @gradient-railway-engineering;
        }
    }
}