
.hero-teaser {
    position: relative;
    color: @color-white;

    .edit-mode & {
        height: auto;
    }

    .carousel__view {
        margin-bottom: 0;
        display: flex;
        width: 100%;
    }


    /** Slider */

    .carousel {
        position: relative;
        z-index: 2;

        width: 100%;
        margin-bottom: 20px;
    }

    .carousel.hide {
        animation: hideTeaser 0.5s forwards;
    }

    .carousel__view {
        margin-bottom: 0;
        overflow: hidden;
    }

    .carousel__view-wrapper,
    .carousel__view {
        position: relative;
    }

    .carousel__list {
        width: 100%;
        min-height: 600px;
    }

    .carousel__item .carousel__item-img {
        display: block;
        margin: 0 auto;

        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 20% 0;

        @media @screen-sm-min {
            object-position: 10% 0;
        }

        @media @screen-lg-min {
            object-position: 0 0;
        }
    }

    .carousel__item.alignment--default .carousel__item-img {
        object-position: 50%  0;


        @media @screen-lg-min {
            object-position: 0 0;
        }
    }

    .carousel__item {
        left: auto;
        position: absolute;

        display: none;
        width: 100%;
        height: 100%;

        .edit-mode & {
            display: block;
            position: relative;
        }

        --gradient: none;

        &:after {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            background-image: var(--gradient);
            pointer-events: none;
        }


        &.carousel-theme--commercial:after {
           --gradient: @gradient-commercial;
        }

        &.carousel-theme--technical:after {
           --gradient: @gradient-technical;
        }

        &.carousel-theme--railway-engineering:after {
           --gradient: @gradient-railway-engineering;
        }
    }

    .carousel__item--active {
        top: 0;

        display: block;
        width: 100%;

        transform: translateX(0);
    }

    /** Animations */

    .carousel__item--slide-in-left {
        transform: translateX(-100%);
    }

    .carousel__item--slide-in-right {
        transform: translateX(100%);
    }

    .carousel__item--slide-out-left {
        transform: translateX(-100%);
    }

    .carousel__item--slide-out-right {
        transform: translateX(100%);
    }

    .carousel__item.carousel__item--is-sliding {
        position: absolute;
        top: 0;
    }

    .carousel__text {
        position: absolute;
        width: 100%;
        padding: 10px;
        bottom: 60/@px;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 1;
        color: @color-white;

        @media @screen-md-min {
            width: auto;
            bottom: 212/@px;
            left: 50%;
            transform: translateX(-50%);
        }

        .headline,
        .preheadline,
        .subheadline,
        .title {
            color: @color-white;
        }
    }

    .alignment--left .carousel__text {
        @media @screen-md-min {
            width: auto;
            left: 0;
            right: auto;
            transform: translateX(50%);
        }
    }

    .alignment--right .carousel__text {
        @media @screen-md-min {
            width: auto;
            left: auto;
            right: 0%;
            transform: translateX(-50%);
        }
    }

    /** Forward/Backward Controls */

    .carousel__control-forward,
    .carousel__control-backward {
        position: absolute;
        top: 50%;

        display: none;
        width: 35px;
        height: 35px;
        color: @color-white;
        cursor: pointer;
        transform: translateY(-50%);
        transition: transform .25s ease;

        & [class*='nasicher-icon'] {
            width: 40px;
            height: 40px;
        }

        &:hover,
        &:focus {
            transform: scale(1.1) translateY(-50%);
        }
    }

    .carousel__control-forward {
        right: 0;
    }

    .carousel__control-backward {
        left: 0;
    }


    @media screen and (min-width: 768px) {
        .carousel__control-backward,
        .carousel__control-forward {
            display: block;
            width: 65px;


            & [class*='nasicher-icon'] {
                width: 68px;
                height: 68px;
            }
        }
    }



    /** Indicators */

    .carousel__indicators {
        z-index: 1;

        display: block;

        text-align: center;
    }

    .carousel__indicator {
        display: inline-block;
        padding: 8px 0;
        background: none;
        border: none;
        cursor: pointer;
    }

    .carousel__indicator::after {
        content: '';
        display: block;
        width: 15px;
        height: 15px;
        background: transparent;
        border: 2px solid @color-white;
        border-radius: 50%;
    }

    .carousel__indicator:focus::after,
    .carousel__indicator:hover::after,
    .carousel__indicator:active::after,
    .carousel__indicator--active::after {
        background: @color-white;
    }

    /** Controls */

    .carousel__bottom {
        position: absolute;
        bottom: 30/@px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        display: grid;
        justify-content: center;

        @media @screen-md-min {
            grid-template-rows: 1fr;
            grid-gap: 25/@px;
        }
    }

    .carousel__hint {
        display: none;

        @media @screen-md-min {
            display: grid;
            grid-template-rows: 1fr;
            grid-gap: 16/@px;
            justify-content: center;
            text-transform: uppercase;
            font-size: 16/@px;
            animation: moveInBottom 1s ease-out;
            animation-fill-mode: backwards;

            svg {
                justify-self: center;
                width: 26/@px;
                height: 52/@px;
            }
        }
    }

    .carousel__controls {
        display: flex;
        justify-content: center;
    }

    .carousel__control-play {
        display: block;
        margin: 0 16px;
        background: none;
        border: none;
        box-shadow: none;

        cursor: pointer;

        text-align: center;
        text-decoration: none;
        transition: transform .25s ease;

        &:hover,
        &:focus {
            transform: scale(1.1);
        }
    }

    .carousel__control-play:link,
    .carousel__control-play:visited,
    .carousel__control-play:focus,
    .carousel__control-play:hover,
    .carousel__control-play:active {
        text-decoration: none;
    }

    .carousel__icon-play {
        display: inline-block;

        font-size: 30px;
    }

    .carousel__icon-pause {
        display: none;

        font-size: 30px;
    }

    &.carousel--sliding .carousel__icon-pause {
        display: inline-block;
    }

    &.carousel--sliding .carousel__icon-play {
        display: none;
    }

    /** Property z-index should be higher than in Hero-Teaser elements, because Hero-Teaser can be used as items */

    .carousel__control-forward,
    .carousel__control-backward,
    .carousel__indicators {
        z-index: 3;
    }

    /** Magnolia edit mode */

    .edit-mode .carousel__control-forward,
    .edit-mode .carousel__control-backward {
        max-height: 50%;
    }

    .edit-mode .carousel__item {
        display: block;
    }

}