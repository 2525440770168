.iframe {
	margin-top: 20px;

	iframe {
		max-width: 100%;
        width: 100%;
	    height: 450px;
        border: none;
	}

}
