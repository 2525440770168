.component--sociallinks {
    padding: 0;
    max-width: none;

    > .inner {
        padding: 0;
    }
}

.social-links {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    flex-wrap: wrap;
    gap: 15/@px;

    .nasicher-icon {
        width: 32px;
        height: 32px;
    }
}

.social-links__title {
    margin-bottom: 0;

}

.social-links__list {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    list-style: none;
}

.social-links__link {
    color: #ffffff;
    text-decoration: none;
    margin: 0 12.5px;
    font-size: 32px;

    &:hover,
    &:focus {
        text-decoration: none;
    }
}