.footer {
    background: #ffffff;
    padding: 46px 0 48px;
}

.service-link-list {
    margin: 0;
    padding: 0;
    list-style: none;

    &__item {
        margin-left: 35px;
        display: inline-block;
    }
}

.awards {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 50px;

    .awards-img{
        display:inline-block;
        margin:16/@px;
        height: auto;
        max-width: 35vw;

        @media @screen-xs-min{
            max-height:120px;
            width:auto;
        }
    }
}