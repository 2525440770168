
.data-box {
    padding: 16/@px 24/@px;
    background-color: var(--primary-color);
    color: @color-white;
    border-radius: @border-radius-big;
    font-size: 18/@px;
    line-height: 24/@px;

    .title {
        .font-weight-heavy();
        color: currentColor;
    }

    @media @screen-md-min {
        padding: 50/@px 108/@px;

        .title {
            font-size: 40/@px;
        }
    }

    table {
        border-collapse: collapse;
        width: 100%;
    }

    tr {
        border-bottom: 1px solid rgba(255,255,255,.6);
        padding: 16px 0;
        display: block;

        @media @screen-md-min {
            padding: 0;
            display: table-row;
        }
    }

    th, td {
        text-align: left;
        padding: 0;
        display: block;

        @media @screen-md-min {
            padding: 16px 0;
            display: table-cell;
        }
    }

    th {
        .font-weight-bold();
        text-transform: uppercase;
        padding-right: 20px;
    }

    td {
        .font-weight-medium();

        @media @screen-md-min {
            padding-left: 20px;
        }
    }
}

